<script setup>
  import { ref } from 'vue'
 
  import { onMounted } from 'vue'
  
onMounted(() => {
  window.scrollTo(0, 0);
})
  const image = ref(require("@/assets/inf/database/SQL_3.png"))
</script>

<template>
  <div class="container">

    <div class="row animate glow delay-1">
      <h1>Datenbankmanagementsystem (DBMS)</h1>

      <p>Ein Datenbankmanagementsystem (DBMS) ist eine Software, die das Erstellen, Verwalten und Verändern einer Datenbank ermöglicht. Wichtige Funktionen eines DBMS umfassen:</p>
    <ul>
        <li><strong>CREATE</strong>:  Diese SQL-Anweisung erstellt neue Tabellen (CREATE TABLE …) oder Datenbanken (CREATE DATABASE …).</li>
        <li><strong>INSERT INTO</strong>: Diese SQL-Anweisung fügt neue Daten in Tabellen ein.</li>
        <li><strong>UPDATE</strong>:  Diese SQL-Anweisung ändert vorhandene Daten in einer Tabelle.</li>
        <li><strong>DELETE</strong>:  Diese SQL-Anweisung entfernt vorhandene Daten aus einer Tabelle.</li>
    </ul>
    <p> Diese SQL-Anweisungen sind Teil der SQL-Sprache, die für die Interaktion mit einem DBMS verwendet wird. Sie ermöglichen es, Benutzer:innen, Daten effizient anzulegen und zu verwalten.</p>
      
    
    </div>

  
      
        <div id = 'image-box' class = "animate glow delay-4">
          <img :src= 'image' id = "SQL_3"/>
          
        </div>
        
      
   
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}

#image-box {
  display: flex; justify-content: center; align-items: center;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



ul li {
  margin: 0 0 24px;
  list-style-type: none;
  line-height: 1.3;
}


.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-2 {
  animation-delay: 0.7s;
}
.delay-3 {
  animation-delay: 0.8s;
}
.delay-4 {
  animation-delay: 1.2s;
}

</style>
