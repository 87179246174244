<script setup>
  import { ref } from 'vue'
 
  const image1 = ref(require("@/assets/inf/database/CREATE.png"))
  const image = ref(require("@/assets/inf/database/TABLE_CREATE.png"))
  const image2 = ref(require("@/assets/inf/database/arrow.png"))
  import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })
</script>

<template>
  <div class="container">

    <div class="row animate glow delay-1">
      <h1>CREATE TABLE</h1>

      <p> Die SQL-Anweisung <strong>CREATE TABLE</strong> wird verwendet, um eine <strong>neue Tabelle in einer Datenbank zu erstellen</strong>. Sie ermöglicht es Ihnen, die Struktur der Tabelle 
          zu definieren, indem Sie die Namen jeder Spalte (Felder) zusammen mit ihren Datentypen angeben.</p>
      <p>Die Syntax lautet:  
        <strong>
        <ul>
       <li>CREATE TABLE tabellen_name (</li> 
            <li>spalte1 datentyp,</li>
            <li>spalte2 datentyp,</li>
            <li>spalte3 datentyp,</li>
           <li>...);</li>
          </ul> </strong>

          <ul>
          <li> <strong>tabellen_name</strong> ist der Name der Tabelle, die Sie erstellen möchten.</li> 
          <li><strong>spalte1, spalte2, spalte3</strong> ... sind die Namen der Spalten in der Tabelle</li> 
          <li><strong>datentyp</strong> ist der Typ der Daten, die die Spalte haben kann (INT für einfache Zahlen, DOUBLE für Dezimal, VARCHAR für Wörter usw.).</li>
        </ul>
      </p>
    </div>

  
      
         <div class="images-container animate glow delay-4">

          <img :src= 'image1' id = "CREATE" class="image1"/>
          <div class="images-container2 animate glow delay-4">
          <img :src= 'image2' id = "arrow" class="image2"/>
            </div>
          <img :src= 'image' id = "TABLE_CREATE" class="image"/>
         
        </div>
      
        
      
   
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}

.images-container {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 5px;
}
.images-container2 {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 2px;
}

.image {
  width: 26%; /* Limit the image width to half of the container */
  height: 10%; /* Keep the image aspect ratio */
}
.image1 {
  width: 25%; /* Limit the image width to half of the container */
  height: 10%; /* Keep the image aspect ratio */
}
.image2 {
  width: 40%; /* Limit the image width to half of the container */
  height: 20%; /* Keep the image aspect ratio */
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



ul li {
  margin: 0 0 2px;
  list-style-type: none;
}


.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-2 {
  animation-delay: 0.7s;
}
.delay-3 {
  animation-delay: 0.8s;
}
.delay-4 {
  animation-delay: 1.2s;
}

</style>
