<script setup>
  import { ref } from 'vue'
 
  import { onMounted } from 'vue'
  
onMounted(() => {
  window.scrollTo(0, 0);
})
  const image1 = ref(require("@/assets/inf/database/App.png"))

</script>
<template>
  <div class="container">

    

    <div class="row animate glow delay-2">
            <p>   
              Die App hat es Ihnen erleichtert und die Restaurants in Kategorien eingeteilt. Zum Beispiel: Sie möchten einen Burger bestellen? 
              Sie klicken auf die Kategorie 'Burger' und wählen dort Ihr Lieblingsrestaurant aus. Oder Sie können einfach nach dem Namen des Restaurants suchen, von dem Sie bestellen möchten.</p>

            <p>
              Wir betrachten diese App als die Schnittstelle zu einer Datenbank. Eine Datenbank wird allgemein zur Speicherung verwandter, strukturierter Daten mit wohldefinierten Datenformaten auf effiziente Weise für das Einfügen, Aktualisieren und/oder Abrufen verwendet.
            </p>
             
            <p>
              Im Kontext von Datenbanken würden Ihre Aktionen in dieser App durch eine bestimmte Sprache zusammengefasst, die wir SQL (Structured Query Language) nennen. 
              Die SQL bereitet Ihre Abfrage für die Datenbank vor, damit sie bearbeitet werden kann. Sie ist sozusagen die Computersprache zur Verwendung von Datenbanken.
           </p>
           <div class="images-container animate glow delay-4">
      
     
   
     <div class="images-container1 animate glow delay-5">
      <img :src="image1" alt="App" class="image1" />
    </div>
    </div>
    
    <div class="image-text">App. zur Bestellung und Lieferung</div>

    </div>
   
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}
.images-container {
  display: flex; 
  justify-content: center;
  align-items: center; 
  gap: 20px;
}
.images-container1 {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  gap: 20px;
}

.image1 {
  max-width: 95%; 
  height: 100%; 
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-2 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 1s;
}
.image-text{
  text-align: center;
  font-size: small;
}
</style>
