<script setup>
  import { ref } from 'vue'
  import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })

 const image1 = ref(require("@/assets/inf/database/SQL_1.png"))
</script>
<template>
  <div class="container">
    <div class="row animate glow delay-1">
     <h1>Was ist SQL?</h1>
    

       <div class="row animate glow delay-2">
            <p>   
              Kurz kann man den Aufbau und die Funktionsweisen der SQL Abfragen wie in der folgenden Abbildung zusammenfassen:</p>
              </div>

    </div>
    <div class="images-container1 animate glow delay-5">
      <img :src="image1" alt="App" class="SQL_1" />
    </div>
    </div>
    
   
 
</template>

<style scoped>
p{
  line-height: 1.3;
}

.images-container1 {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 10px;
}

.image1 {
  max-width: 95%; /* Limit the image width to half of the container */
  height: 100%; /* Keep the image aspect ratio */
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-2 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 1s;
}
</style>
