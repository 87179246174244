<script setup>
import { ref } from 'vue'
import { onMounted } from 'vue'
import Bar from '@/components/inf/database/helpers/Bar.vue'
import Intro1 from '@/components/inf/database/Assignment1/Intro1.vue'
import Intro1_2 from '@/components/inf/database/Assignment1/Intro1_2.vue'
import Intro1_3 from '@/components/inf/database/Assignment1/Intro1_3.vue'
import Intro1_4 from '@/components/inf/database/Assignment1/Intro1_4.vue'
import Intro1_4_1 from '@/components/inf/database/Assignment1/Intro1_4_1.vue'
import Intro1_4_2 from '@/components/inf/database/Assignment1/Intro1_4_2.vue'
import Intro1_5 from '@/components/inf/database/Assignment1/Intro1_5.vue'
import Intro1_6 from '@/components/inf/database/Assignment1/Intro1_6.vue'
import Intro1_7 from '@/components/inf/database/Assignment1/Intro1_7.vue'
import Tutorial from '@/components/inf/database/Assignment1/Tutorial.vue'
import Assignment1_1 from '@/components/inf/database/Assignment1/Assignment1_1.vue'
import Assignment1_2 from '@/components/inf/database/Assignment1/Assignment1_2.vue'
import Assignment1_3 from '@/components/inf/database/Assignment1/Assignment1_3.vue'
import Intro2 from '@/components/inf/database/Assignment2/Intro2.vue'
import Assignment2_1 from '@/components/inf/database/Assignment2/Assignment2_1.vue'
import Assignment2_2 from '@/components/inf/database/Assignment2/Assignment2_2.vue'
import Intro3 from '@/components/inf/database/Assignment3/Intro3.vue'
import Intro3_1 from '@/components/inf/database/Assignment3/Intro3_1.vue'
import Intro3_2 from '@/components/inf/database/Assignment3/Intro3_2.vue'
import Intro3_3 from '@/components/inf/database/Assignment3/Intro3_3.vue'
import Intro3_4 from '@/components/inf/database/Assignment3/Intro3_4.vue'
import Assignment3_1 from '@/components/inf/database/Assignment3/Assignment3_1.vue'
import Assignment3_2 from '@/components/inf/database/Assignment3/Assignment3_2.vue'
import Assignment3_3 from '@/components/inf/database/Assignment3/Assignment3_3.vue'
import Assignment3_4 from '@/components/inf/database/Assignment3/Assignment3_4.vue'
import Appendix from '@/components/inf/database/Appendix.vue'
import * as scrollHelper from "@/helpers/scroll-helper.js"
import router from "@/router"
import store from "@/store"
import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js"

onMounted(() => {
  scrollHelper.scrollToTop();
});

const currentTab = ref(0)
const tabs = [Intro1, Intro1_2, Intro1_3, Intro1_4, Intro1_4_1, Intro1_4_2, Intro1_5, Intro1_6, Intro1_7, Tutorial, Assignment1_1,Assignment1_2,Assignment1_3, Intro2 , Assignment2_1,Assignment2_2, Intro3, Intro3_1, Intro3_2, Intro3_3, Intro3_4, Assignment3_1, Assignment3_2, Assignment3_3, Assignment3_4, Appendix ]
const next = ref(true)

function forwardBtnClicked() {
  currentTab.value++
}
function backBtnClicked() {
  enable()
  currentTab.value--
}
async function finishBtnClicked() {
  const myOSA = await myOSAhandler.getMyInformatikOSA(
  store.getters.getUser.username
  )
  //set securityFinished=true in backend
  await myOSAhandler.finishModule(myOSA.id, { databaseFinished: true })
  router.push("/informatik")
}
function disable() {
  next.value = false
}
function enable() {
  next.value = true
}

</script>

<template>
<div class = 'container'>
  <Bar
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    />
  <component @disable-weiter="disable" @enable-weiter="enable" :is = 'tabs[currentTab]'/>
  <div class="custom-btn" @click = 'forwardBtnClicked' v-if = 'next && currentTab < tabs.length - 1 ? true : false'>Weiter</div>
  <div class="custom-btn" @click = 'finishBtnClicked' v-if = 'currentTab == tabs.length - 1'>Modul beenden</div>
  <div class="custom-btn-secondary" @click = 'backBtnClicked' v-if = 'currentTab > 0 ? true : false'>Zurück</div>
</div>
</template>
<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}</style>
