<script setup>
  import { ref } from 'vue'
 
  const image = ref(require("@/assets/inf/database/TABLE_UPDATE.png"))
  const image1 = ref(require("@/assets/inf/database/UPDATE.png"))
  const image2 = ref(require("@/assets/inf/database/arrow.png"))
  import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })
</script>

<template>
  <div class="container">

    <div class="row animate glow delay-1">
      <h1>UPDATE</h1>

      <p>Die SQL-Anweisung <strong>UPDATE</strong> wird verwendet, um <strong>bestehende Datensätze in einer Tabelle zu ändern</strong>.</p>
    <ul>
      <li>Die Syntax dieser Anweisung lautet:</li>
        <strong><li>UPDATE tabellen_name</li>
        <li>SET spalte1 = wert1, spalte2 = wert2, ...</li>
        <li>WHERE bedingung;</li> </strong>
        
    </ul>
    <ul>
          <li> <strong>tabellen_name</strong> ist der Name der Tabelle, die Sie verändern möchten.</li> 
          <li><strong>spalte1, spalte2, spalte3</strong> ... sind die Namen der Spalten in der Tabelle, in denen Sie die Daten ändern möchten</li> 
          <li><strong>bedingung</strong> ist die Bedingung in WHERE, wonach Sie die Auswahl in der Tabelle spezifizieren, wie Sie in vorherigen Beispielen öfters angewendet haben.</li>
        </ul>
    
    </div>

  
      
    <div class="images-container animate glow delay-4">

      <img :src= 'image1' id = "UPDATE" class="image1"/>
      <div class="images-container2 animate glow delay-4">
      <img :src= 'image2' id = "arrow" class="image2"/>
      </div>
      <img :src= 'image' id = "TABLE_UPDATE" class="image"/>

    </div>
   
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}
.images-container {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 5px;
}
.images-container2 {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 2px;
}

.image {
  width: 26%; /* Limit the image width to half of the container */
  height: 10%; /* Keep the image aspect ratio */
}
.image1 {
  width: 25%; /* Limit the image width to half of the container */
  height: 10%; /* Keep the image aspect ratio */
}
.image2 {
  width: 40%; /* Limit the image width to half of the container */
  height: 20%; /* Keep the image aspect ratio */
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



ul li {
  margin: 0 0 2px;
  list-style-type: none;
}


.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-2 {
  animation-delay: 0.7s;
}
.delay-3 {
  animation-delay: 0.8s;
}
.delay-4 {
  animation-delay: 1.2s;
}

</style>
