<script setup>
  import { ref } from 'vue'
  import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })
  
  const image = ref(require("@/assets/inf/database/SQL_2.png"))
</script>

<template>
  <div class="container">

    <div class="row animate glow delay-1">
      <h1>WHERE</h1>
      
      <p>WHERE in SQL wird verwendet, um die Datensätze zu filtern, die von einer SELECT, UPDATE, DELETE oder anderen Arten von SQL Anweisungen zurückgegeben oder beeinflusst werden.
         Es ermöglicht die Spezifizierung einer Bedingung, sodass nur jene Zeilen betroffen sind, die dieser Bedingung entsprechen. Durch die Verwendung von WHERE kann man beispielsweise 
         in einer Datenbanktabelle nach spezifischen Informationen suchen, Datenaktualisierungen auf eine ausgewählte Gruppe von Datensätzen beschränken oder nur bestimmte Datensätze löschen.</p>
      
      <!-- 
        Im ersten Teil haben wir Sie in die grundlegendste Form einer Abfrage eingeführt (SELECT * FROM WHERE). 
        Es gibt jedoch noch viel mehr bei SQL. In diesem Teil werden wir uns auf die WHERE-Klausel konzentrieren.
         denken würden, ist das dynamische Arbeiten mit und Weiterverarbeiten von Daten auch ein grundsätzliche Herangehensweise in der Programmierung.
        p>In diesem Fall kann die Klausel mehr als eine Bedingung haben (z. B. Sie suchen ein Restaurant, das Pizza UND Pasta serviert, oder Sie möchten ein Restaurant, das entweder italienisch ODER mexikanisch ist).</p>
        
        --> 
      

     
    </div>

   
    <div class = "row">
      <div id = 'example'>
        <div id = 'image-box' class = "animate glow delay-4">
          <img :src= 'image' id = "SQL_2.png"/>
          
        </div>
       
      </div>
    </div>
    <p></p>
 
    
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}
#example {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
#image-box {
  display: flex; justify-content: center; align-items: center;  
}


#bubble-box {
  text-align: left;
  padding: 20px;
}
#example-text {
  font-weight: bold;
  text-align: center;
}
#text-box {
  width: 900px;
}
#variables-box {
  display: flex; flex-direction: row; gap: 20px;
}
#speech-text {
  font-size: 16pt;
}




* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.7s;
}
</style>
