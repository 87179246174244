<script setup>
  import { ref } from 'vue'
  import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })

  const image = ref(require("@/assets/inf/database/Restaurants.png"))
  const image1 = ref(require("@/assets/inf/database/App.png"))
  const image2 = ref(require("@/assets/inf/database/arrow.png"))
</script>
<template>
  <div class="container">

    

    <div class="row animate glow delay-2">
            <p>   
              Wäre es nicht schön, wenn alles kategorisiert und strukturiert wäre, sodass es für Sie einfacher ist, eine Auswahl zu treffen? Das ist möglich! Deshalb wählen Sie eine App., die Ihnen das Essen, das Sie bestellen möchten, liefert:
           </p>

           <div class="images-container animate glow delay-4">

            <div class="image-and-text-container animate glow delay-4">
        <img :src="image" alt="Restaurants" class="image" />
        <div class="Image-text">Liste Ihrer Lieblingsrestaurants</div>
      </div>

      <div class="images-container2 animate glow delay-3">
    <img :src="image2" alt="arrow" class="image2" />
    </div>
    
    <div class="images-container1 animate glow delay-5">
      <div class="image-and-text-container animate glow delay-5">
        <img :src="image1" alt="App" class="image1" />
        <div class="Image-text">App. zur Bestellung und Lieferung</div>
      </div>
      
    </div>
    </div>

  

    </div>
   
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}
.images-container {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 20px;
}
.images-container1 {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 20px;
}
.images-container2 {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 5px;
}

.image {
  max-width: 80%; /* Limit the image width to half of the container */
  height: 100%; /* Keep the image aspect ratio */
}
.image1 {
  max-width: 95%; /* Limit the image width to half of the container */
  height: 100%; /* Keep the image aspect ratio */
}
.image2 {
  max-width: 50%; /* Limit the image width to half of the container */
  height: 20%; /* Keep the image aspect ratio */
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-2 {
  animation-delay: 0.7s;
}
.delay-3 {
  animation-delay: 1.3s;
}
.delay-4 {
  animation-delay: 1s;
}
.delay-5 {
  animation-delay: 1.6s;
}
.image-and-text-container {
  display: flex; 
  flex-direction: column; 
  align-items: center;
  justify-content: flex-start; 
}

.Image-text {
  text-align: center; 
  margin-top: 10px; 
  font-size: small;
}
</style>
