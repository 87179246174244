<template>
<div class="row animate glow delay-1">
  <h1>Aufgabe 1.3</h1>
  <p><strong>Wählen</strong> Sie alle <strong>italienischen</strong> Restaurants aus der <strong>restaurants</strong>-Tabelle <strong>aus</strong>.</p>
</div>

  <div :class="['sql-input', { 'correct': isCorrect, 'incorrect': isIncorrect }]">
    <div v-if="!isCorrect && !isIncorrect">
      <span contenteditable="true" class="editable"></span> * 
       <span contenteditable="true" class="editable"></span> 
      restaurants
       <span contenteditable="true" class="editable"></span> typ = 
      <span contenteditable="true" class="editable" style="min-width: 0px;">''</span>;
    </div>
    <div v-if="isCorrect || isIncorrect" class="query-result">{{ resultMessage }}</div>
  </div>
  
  <div class="button-group">
    <button v-if="!isCorrect && !isIncorrect" type="button" class="submit-button" @click="handleSubmit">ABSCHICKEN</button>
    <button v-if="isIncorrect" type="button" class="repeat-button" @click="resetForm">WIEDERHOLEN</button>
    
  </div>
    
     <div class="database-table">
    <table>
<thead>
  <tr>Restaurants</tr>
  <tr>
    <th>ID</th> <!-- New column for ID -->
    <th>Name</th>
    <th>PLZ</th>
    <th>Typ</th>
    <th>Essen</th>
  </tr>
</thead>
<tbody>
  <tr v-for="restaurant in restaurants" :key="restaurant.id">
    <td>{{ restaurant.id }}</td> 
    <td>{{ restaurant.name }}</td>
    <td>{{ restaurant.plz }}</td>
    <td>{{ restaurant.typ }}</td>
    <td>{{ restaurant.essen }}</td>
   

  </tr>
</tbody>
</table>
  </div>

<div class="additional-box">
  {{ additionalMessage }}
</div>

</template>

<script>
  import { onMounted } from 'vue'
  import store from "@/store.js"
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })
export default {
data() {
  return {
  allRestaurants: [
    { id: 1, name: "Burger Palace", plz: "1090", typ: "Amerikanisch",  essen: "Burger", },
    { id: 2, name: "Sushi World", plz: "1010", typ: "Asiatisch", essen: "Sushi", },
    { id: 3, name: "Pizza Town", plz: "1190",  typ: "Italienisch",essen: "Pizza", },
    { id: 4, name: "Italy Delight", plz: "1050", typ: "Italienisch",essen: "Pasta", },
    { id: 5, name: "Grill House", plz: "1010", typ: "Amerikanisch",essen: "Burger", },
  ],
  restaurants: [],
    resultMessage: '',
    numberMistakes: 0,
    correctQuery: "SELECT * FROM restaurants WHERE typ = 'Italienisch';",
    isCorrect: false,
    isIncorrect: false,
  };
},
mounted() {
    // Initially display all restaurants
    this.restaurants = this.allRestaurants;
    this.$emit('disableWeiter');
  },

  computed: {
    additionalMessage() {
    if (this.numberMistakes === 0) {
      return ''; // No mistakes yet, no message
    } else if (this.numberMistakes === 1) {
      return 'Ihre Lösung ist nicht korrekt, bitte versuchen Sie es erneut.';
    } else if (this.numberMistakes === 2) {
      return 'Erinnern Sie sich an die Schlüsselsyntax dieser SQL-Abfrage!';
    } else if (this.numberMistakes >= 3) {
      // Make sure to use the correctQuery from the data
      return `Die korrekte Lösung für diese Übung ist: ${this.correctQuery}`;
    
      
    }
    return ''; // This is a catch-all return to satisfy the rule that all paths must return a value
  }
},

methods: {
  handleSubmit() {
    const queryParts = document.querySelectorAll('.editable');
    const userQuery = `${queryParts[0].innerText.trim()} * ${queryParts[1].innerText.trim()} restaurants ${queryParts[2].innerText.trim()} typ = ${queryParts[3].innerText.trim()};`.toUpperCase();
    const correctQuery = "SELECT * FROM restaurants WHERE typ = 'italienisch';".toUpperCase();

    if (userQuery === correctQuery) {
      this.resultMessage = "Richtig!" + " " + userQuery;
      this.isCorrect = true;
      this.isIncorrect = false;
      this.additionalMessage = 'Das ist das Ergebnis Ihrer SQL-Abfrage.';
      this.logCommit(this.numberMistakes)
      this.restaurants = this.allRestaurants.filter(restaurant => restaurant.typ === 'Italienisch');
      this.$emit('enableWeiter')
      this.numberMistakes = 0;
    } else {
      this.resultMessage = "FEHLER";
      this.isCorrect = false;
      this.isIncorrect = true;
      this.addMistake();
    }
  },
  addMistake() {
      // save number of mistakes
      this.numberMistakes++;
      if (this.numberMistakes === 1) {
        this.additionalMessage = 'Ihre Lösung ist nicht korrekt, bitte versuchen Sie es erneut.';
      } else if (this.numberMistakes === 2) {
        this.additionalMessage = 'Erinnern Sie sich an die Schlüsselsyntax dieser SQL-Abfrage!';
      } else if (this.numberMistakes > 2) {
     
        this.additionalMessage = `Die korrekte Lösung für diese Übung ist: ${this.correctQuery}`;
       
        
        this.isCorrect = false;
        this.isIncorrect = true;
       
        
      }
    },
    logCommit(numMist) {
      store.commit("databaseA13SaveMistakes", numMist);
    },
  resetForm() {
    this.resultMessage = '';
    this.additionalMessage = '';
    this.isCorrect = false;
    this.isIncorrect = false;
    this.restaurants = this.allRestaurants;
    const queryParts = document.querySelectorAll('.editable');
    queryParts.forEach(part => part.innerText = '');

  },
  goToNext() {
    // Implement the logic for proceeding to the next step
    console.log('Proceeding to the next step');
  }
},
};
</script>

<style scoped>

p{
  line-height: 1.3;
}
.database-table table {
  width: 40%;
  border-collapse: collapse;
  text-align: left;
  margin-top: 30px;
  margin-left: auto;
  margin-right: 30px;
  
}

.database-table th,.database-table td {
  border: 1px solid #ddd;
  padding: 8px;

}

.database-table th {
  background-color: #f2f2f2;
}
.database-table thead {
  background-color: #bcbbbb;
  padding: 8px;
  text-align: left;
  font-style: italic;

}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}

.assignment {
  width: 80%;
  margin: auto;
  text-align: left;
}

.sql-input {
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid #ccc;
  box-sizing: border-box;
  transition: background-color 0.3s;
}

.editable {
  display: inline-block;
  min-width: 100px;
  margin: 0;
  padding: 2px;
  border-bottom: 1px solid #ccc;
  background-color: whitesmoke;
}

.query-result {
  text-align: center;
  width: 100%;
}

.correct {
  background-color: lightgreen;
}

.incorrect {
  background-color: lightcoral;
  color: white;
}

.button-group {
  display: flex;
  justify-content: flex-start;
}

.submit-button, .repeat-button, .next-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-button, .repeat-button {
  background-color: #4CAF50;
  color: white;
}

.submit-button:hover, .repeat-button:hover {
  background-color: #45a049;
}

.next-button {
  background-color: #777777;
  color: rgb(74, 73, 73);
  margin-left: 10px;
}

.additional-box {
  width: 100%;
  margin: 10px auto;
  padding: 1px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  min-height: 100px;
  margin-top: 40px;
}


.button-active {
  background-color: #4CAF50; /* Or any other color for active state */
  color: white;
}

.next-button:disabled {
  background-color: #e7e7e7; /* Gray color for disabled state */
  color: rgb(123, 117, 117);
  cursor: not-allowed; /* Optional: Shows a 'not allowed' cursor */
}
</style>
