User
<template>
   <div class="container">
    <div class="row animate glow delay-1">
      <h1>Tutorial</h1>
      <p> Jetzt versuchen Sie selber eine SQL-Abfrage zu schreiben. Die Aufgabe ist  eine SQL-Abfrage zu erstellen, mit der Sie alle Restaurants mit einer Bewertung > 3 auswählen. </p>
      <p>Was ist der erste Teil dieser SQL-Abfrage, in der wir alle Restaurants mit einer Bewertung größer als 3 <strong>auswählen</strong>?</p>
      </div>
      
     <div :class="['sql-input', { 'correct': isCorrect, 'incorrect': isIncorrect }]">
  <div v-if="!isCorrect && !isIncorrect">
    <span contenteditable="true" class="editable"></span> * FROM 
          restaurants
          WHERE Bewertung > 3 ; 
        </div>
        <div v-if="isCorrect || isIncorrect" class="query-result">{{ resultMessage }}</div>
      </div>
      
      <div class="button-group">
        <button v-if="!isCorrect && !isIncorrect" type="button" class="submit-button" @click="handleSubmit">ABSCHICKEN</button>
        <button v-if="isIncorrect" type="button" class="repeat-button" @click="resetForm">WIEDERHOLEN</button>
        <button v-if="!isIncorrect" type="button" class="next-button" :class="{ 'button-active': isCorrect }" @click="goToNext" :disabled="!answerSubmitted || !isCorrect"> WIEDERHOLEN </button>
      </div>
    </div>

    <div class="database-table">
      <table>
        <thead>
          <tr>Restaurants</tr>
          <tr>
            <th>Name</th>
            <th>PLZ</th>
            <th>Bewertung</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="restaurant in restaurants" :key="restaurant.name">
            <td>{{ restaurant.name }}</td>
            <td>{{ restaurant.plz }}</td>
            <td>{{ restaurant.bewertung }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="additional-box">
      {{ additionalMessage }}
    </div>
</template>

<script>
  import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })
export default {
  data() {
    return {
     allRestaurants: [
        { name: "Burger Palace", plz: "1090", bewertung: 3 },
        { name: "Sushi World", plz: "1010", bewertung: 3 },
        { name: "Pizza Town", plz: "1190", bewertung: 5 },
        { name: "Vegan Delight", plz: "1050", bewertung: 2.5 },
        { name: "Grill House", plz: "1010", bewertung: 4.4 },
        { name: "Pasta Heaven", plz: "1020", bewertung: 4 }
      ],
      restaurants: [],
      resultMessage: '',
      additionalMessage: '',
      isCorrect: false,
      isIncorrect: false,
      answerSubmitted: false,
    };
  },
  mounted() {
    // Initially display all restaurants
    this.restaurants = this.allRestaurants;
  },
  methods: {
    handleSubmit() {
      const queryParts = document.querySelectorAll('.editable');
      const userQuery = `${queryParts[0].innerText.trim()} * FROM restaurants WHERE Bewertung > 3;`.toUpperCase();
      const correctQuery = "SELECT * FROM restaurants WHERE Bewertung > 3;".toUpperCase();

      if (userQuery === correctQuery) {
        this.resultMessage = correctQuery;
        this.isCorrect = true;
        this.isIncorrect = false;
        this.additionalMessage = 'Das ist das Ergebnis Ihrer SQL-Abfrage.';

        this.restaurants = this.allRestaurants.filter(restaurant => restaurant.bewertung > 3);
      } else {
        this.resultMessage = "FEHLER";
        this.isCorrect = false;
        this.isIncorrect = true;
        this.additionalMessage = 'Bitte lesen Sie die Beschreibung noch einmal.';
      }
      this.answerSubmitted = true;
    },
    resetForm() {
      this.resultMessage = '';
      this.additionalMessage = '';
      this.isCorrect = false;
      this.isIncorrect = false;
      this.restaurants = this.allRestaurants;
    },
    goToNext() {
      this.resultMessage = '';
      this.additionalMessage = '';
      this.isCorrect = false;
      this.isIncorrect = false;
      this.restaurants = this.allRestaurants;
    }
  },
};
</script>

<style scoped>
p{
  line-height: 1.3;
}
.database-table table {
  width: 40%;
  border-collapse: collapse;
  text-align: left;
  margin-top: 30px;
  margin-left: auto;
  margin-right: 30px;
  
}

.database-table th,.database-table td {
  border: 1px solid #ddd;
  padding: 8px;

}

.database-table th {
  background-color: #f2f2f2;
}
.database-table thead {
  background-color: #bcbbbb;
  padding: 8px;
  text-align: left;
  font-style: italic;

}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}

.assignment {
  width: 80%;
  margin: auto;
  text-align: left;
}

.sql-input {
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid #ccc;
  box-sizing: border-box;
  transition: background-color 0.3s;
}

.editable {
  display: inline-block;
  min-width: 100px;
  margin: 0;
  padding: 2px;
  border-bottom: 1px solid #ccc;
  background-color: whitesmoke;
}

.query-result {
  text-align: center;
  width: 100%;
}

.correct {
  background-color: lightgreen;
}

.incorrect {
  background-color: lightcoral;
  color: white;
}

.button-group {
  display: flex;
  justify-content: flex-start;
}

.submit-button, .repeat-button, .next-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-button, .repeat-button {
  background-color: #4CAF50;
  color: white;
}

.submit-button:hover, .repeat-button:hover {
  background-color: #45a049;
}

.next-button {
  background-color: #777777;
  color: rgb(74, 73, 73);
  margin-left: 10px;
}

.additional-box {
  width: 100%;
  margin: 10px auto;
  padding: 1px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  min-height: 100px;
  margin-top: 40px;
}


.button-active {
  background-color: #4CAF50; /* Or any other color for active state */
  color: white;
}

.next-button:disabled {
  background-color: #e7e7e7; /* Gray color for disabled state */
  color: rgb(123, 117, 117);
  cursor: not-allowed; /* Optional: Shows a 'not allowed' cursor */
}
</style>