<script setup>
  import { ref } from 'vue'
  import { onMounted } from 'vue'
  
onMounted(() => {
  window.scrollTo(0, 0);
})

  const image = ref(require("@/assets/inf/database/Restaurants.png"))
</script>
<template>
  <div class="container">

    <div class="row animate glow delay-1">
      <h1>Was ist ein Datenbank?</h1>
      
      
       <p>  Stellen Sie sich das vor: Sie haben Ihre Freunde und Freundinnen zu einem Abendessen bei Ihnen zu Hause eingeladen. Alles ist bereit: Getränke, Film und Spiele, aber Sie müssen sich noch für das Essen entscheiden.</p> 
       <p> Sie möchten etwas Italienisches, Jims Lieblingsessen ist Sushi, Berina liebt Burger und Ana möchte Tacos. Sie gehen zu Ihrer Liste Ihrer Lieblingsrestaurants auf Ihrem Handy und versuchen, etwas für jeden und jede auszuwählen, aber die Liste ist zu lang und schlecht organisiert:
      </p>
    </div>

    <div class="image-and-text-container animate glow delay-4">
        <img :src="image" alt="Restaurants" class="image" />
        <div class="Image-text">Liste Ihrer Lieblingsrestaurants</div>
      </div>
  </div>
</template>


<style scoped>
p{
  line-height: 1.3;
}

#image-box {
  display: flex; justify-content: center; align-items: center;  
}




* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.3s;
}
.image-and-text-container {
  display: flex; 
  flex-direction: column; 
  align-items: center;
  justify-content: flex-start; 
}

.Image-text {
  text-align: center; 
  margin-top: 10px; 
  font-size: small;
}
</style>
