<script setup>
  import { ref } from 'vue'
 

  const image1 = ref(require("@/assets/inf/database/app_second.png"))
  const image2 = ref(require("@/assets/inf/database/system_second.png"))
  import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })

</script>

<template>
  <div class="row animate glow delay-1">
    <h1>Wie funktioniert SQL?</h1>
   <p> Sie sehen die Kategorien, die in einer Tabelle in der Datenbank verwaltet werden, als auswählbare Produkte in der App. So sind die meisten Produkte 
        oder Dienstleistungen programmiert, die wir im Internet finden und konsumieren. Oft ist eine Datenbank dahinter, wo die Details der Produkte 
        verwaltet werden.</p>
  </div>

  <div class="images-container animate glow delay-4">
    <div class="image-and-text-container animate glow delay-5">
      <img :src="image1" alt="app_second" class="image" />
      <div class="Image-text">Was Sie sehen</div>
    </div>

    <div class="image-and-text-container animate glow delay-5">
      <img :src="image2" alt="system_second" class="image2" />
      <div class="Image-text">Was das System sieht</div>
    </div>
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}
.images-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 200px;
}

.image {
  max-width: 95%;
  height: 350px; /* Adjusted to maintain aspect ratio */
}
.image2 {
  max-width: 95%;
  height: 300%; /* Adjusted to maintain aspect ratio */
}
.image-and-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Image-text {
  text-align: center;
  margin-top: 10px;
  font-size: small;
}
</style>