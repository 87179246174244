<script>
//import { resolveTransitionHooks } from 'vue';
import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })
export default {
  
  mounted() {
    // Initially display all restaurants
    this.$store.commit("databaseResultsPost", this.$store.state.databaseModule)
  }
}
</script>

<template>
  <div class="container">

    <div class="row animate glow delay-1">
      <h1>Appendix: Datenbanken</h1>
      
      <p>
        In diesem Modul wurden grundlegende Fachkenntnisse im Bereich der Datenbanken vermittelt. 
        Der Sektor der Datenbanktechnologien ist weitreichend und zeichnet sich durch eine stetige Dynamik aus, wobei neue technische Lösungen regelmäßig eingeführt werden. 
        Diese basieren jedoch grundsätzlich auf denselben fundamentalen Prinzipien und Mechanismen. </p>

        <p>Dieses Modul widmete sich primär relationalen Datenbanken, ließ dabei jedoch vertiefende Themen wie 1:1-Beziehungen, die verschiedenen Normalformen und das Ausführen komplexer SQL-Abfragen, einschließlich JOIN-Operationen, unberührt.</p>
    
        <p>Nur als kleines Exzerpt hier, um Ihnen einen Eindruck über die weiteren Aspekte der Datenbanken zu geben:</p>
        <ul>
        Eine 1:1-Beziehung in Datenbanken beschreibt eine Situation, in der eine Zeile in Tabelle A genau einer Zeile in Tabelle B zugeordnet ist und
         umgekehrt. Dies bedeutet, dass jedes Element der einen Tabelle genau einem Element der anderen Tabelle entspricht. Solche Beziehungen werden 
          oft eingesetzt, um Daten logisch zu trennen, die aus konzeptionellen oder praktischen Gründen nicht in einer einzigen Tabelle gespeichert werden sollten.
      </ul>

     <ul>Ein klassisches Beispiel für eine 1:1-Beziehung könnte die Aufteilung von Nutzerdaten in zwei Tabellen sein: Eine Tabelle speichert die Login-Informationen 
         der Nutzer:innen (wie Benutzername und Passwort), während eine zweite Tabelle detaillierte persönliche Informationen enthält (wie Name, Adresse und 
        Geburtsdatum). Jeder Eintrag in der Tabelle mit den Login-Informationen ist genau einem Eintrag in der Tabelle mit den persönlichen Informationen zugeordnet, und 
         umgekehrt.</ul>

       <ul>Um eine 1:1-Beziehung in einer relationalen Datenbank zu implementieren, verwendet man üblicherweise Fremdschlüssel mit einer Einzigartigkeitsbedingung. 
           Das bedeutet, dass der Fremdschlüssel in einer der beiden Tabellen nicht nur auf einen Primärschlüssel in der anderen Tabelle verweist, sondern auch als eindeutig 
           definiert ist, sodass keine zwei Zeilen denselben Wert für diesen Fremdschlüssel haben können. Diese Struktur gewährleistet, dass jede Zeile in einer Tabelle genau 
           einer Zeile in der anderen Tabelle zugeordnet ist, wodurch die 1:1-Beziehung durchgesetzt wird.</ul>

      <p>Neben den relationen Datenbanken (1970) gibt es auch objekt-orientierte Datenbanken (1990), NoSQL und New SQL Datenbanken (2000). Interessenten, die tiefer in diese Materie 
         eintauchen möchten, finden an der TU Wien ein breites Angebot an Lehrveranstaltungen, die sich der detaillierten Erklärung und praktischen Anwendung dieser Konzepte widmen.</p>
     
    </div>

    
        
   
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}
#image-box {
  display: flex; justify-content: center; align-items: center;
}
#text-box {
  width: 900px;
}
#variables-box {
  display: flex; flex-direction: row; gap: 20px;
}


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  padding-inline-start: 70px; /* Adjust this value to increase or decrease indentation */
  list-style-type: none; /* Removes the default list bullet */
}

ul li {
  margin: 0 0 2px;
  /* text-indent: 5%; Remove this line if you don't want the first line of each li to indent further */
}



.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.2s;
}
</style>
