<script setup>
  import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })
</script>
<template>
  <div class="container">
    <div class="row animate glow delay-1">
    <h1>Was ist SQL?</h1>
    

    <div class="row animate glow delay-2">
            <p>   
              Wie garantiert SQL, dass die gesuchten Daten wirklich gefunden werden können? Dafür nutzt sie die sogenannten Primärschlüsselnummer.</p>
              <p>Die Primärschlüsselnummer ist vergleichbar wie die Identifikationsnummer einer realen Person. Sie ist in SQL PRIMARY KEY genannt. 
                Der <strong>PRIMARY KEY</strong> identifiziert jeden Datensatz in einer Tabelle eindeutig. Primärschlüssel müssen <strong>eindeutige</strong> Werte enthalten, und eine Tabelle kann <strong>nur EINEN</strong> Primärschlüssel haben, nicht zwei oder mehr.
</p>
              <p>Noch zwei wichtige Hinweise für die Syntax:</p>
        
              <div>
               <!--<p>In einer SQL-Abfrage werden Textwerte in einfache Anführungszeichen (' ') gesetzt, während Zahlen ohne Anführungszeichen geschrieben werden.
                  Zum Beispiel: SELECT * FROM Restaurants WHERE Name = 'Pizza Paradise'; für Text und für Zahlen: SELECT * FROM Restaurants WHERE Bewertung > 4;. </p>--> 

                   <p>In einer SQL-Abfrage macht es keinen Unterschied, ob Sie eine Schreibweise mit reinen GROSSBUCHSTABEN, Gross-klein Buchstaben oder mit reinen kleinbuchstaben anwenden. 
                    Die SQL wandelt sie um in die eigene interpretierbare Programmiersprache. Aus Einfachkeitsgründen haben wir in den folgenden Beispielen die Gross-klein Schreibweise verwendet.</p>
                

              </div>
              </div>
    </div>
    
    </div>
   
 
</template>

<style scoped>
p{
  line-height: 1.3;
}
.images-container1 {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 20px;
}

.image1 {
  max-width: 95%; /* Limit the image width to half of the container */
  height: 100%; /* Keep the image aspect ratio */
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-2 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 1s;
}
</style>
