<script setup>
  import { onMounted } from 'vue'
  
  onMounted(() => {
    window.scrollTo(0, 0);
  })
</script>
<template>
  <div class="container">
    <div class="row animate glow delay-1">
    <h1>Was ist SQL?</h1>
    

    <div class="row animate glow delay-2">
            <p>   
              SQL steht für Structured Query Language (Strukturierte Abfragesprache) und ermöglicht es Ihnen, auf Datenbanken zuzugreifen und diese zu verändern. 
              Dafür werden die sogenannten Anweisungen verwendet. Die SQL-Abfragen bestehen aus Schlüsselwörtern, die leicht zu verstehen sind. Für den Moment konzentrieren wir uns nur auf die grundlegendste Abfragestruktur: </p>
              <p><strong>SELECT * FROM __ WHERE __ </strong></p>
              <p>Bei dieser SQL-Abfrage geht es um eine Anweisung SELECT, die ermöglicht, Daten aus der Datenbank auszuwählen und sie darzustellen.</p>
        
              <div>
                <p>In einer SQL-Abfrage wird das Sternsymbol (*) verwendet, um alle Spalten (Felder) aus einer Tabelle auszuwählen. Damit können Sie sehr schnell jede verfügbare Datenmenge in der Datenbank abrufen.
                   Aber wenn Sie nur die benötigten Spalten (Felder) auswählen möchten, ist es besser, diese genau zu spezifizieren, indem Sie den Namen der erwünschten Spalte explizit angeben.</p>
                   <p>Noch zwei wichtige Hinweise für die Syntax: In einer SQL-Abfrage werden Textwerte in einfache Anführungszeichen (' ') gesetzt, während Zahlen ohne Anführungszeichen geschrieben werden.</p>
               
                   <p>Zum Beispiel: <strong>* FROM Restaurants WHERE Name = 'Pizza Paradise';</strong>  für Text und für Zahlen: <strong>SELECT * FROM Restaurants WHERE Bewertung > 4;</strong>. </p>
                

              </div>
              </div>
    </div>
    
    </div>
   
 
</template>

<style scoped>
p{
  line-height: 1.3;
}
.images-container1 {
  display: flex; /* This will align children images side by side */
  justify-content: center; /* This will center the images in the container */
  align-items: center; /* This will vertically align the images in the container */
  gap: 20px;
}

.image1 {
  max-width: 95%; /* Limit the image width to half of the container */
  height: 100%; /* Keep the image aspect ratio */
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-2 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 1s;
}
</style>
